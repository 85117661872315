import { cx } from '@emotion/css'
import { Children } from 'react'
import { TabsStyled } from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/tabs/tabs.styled'

const TabsRenderer = ({
	aboveTheFold,
	tabNavigationAlignment = 'left',
	defaultIndex,
	headers = [],
	className,
	children,
	cssProp,
	sx,
}) => {
	if (!headers.length) {
		return null
	}

	return (
		<TabsStyled
			aboveTheFold={aboveTheFold}
			css={cssProp}
			sx={{ ...sx, width: '100%', height: aboveTheFold ? '100%' : undefined }}
			className={cx('Magento-PageBuilder-Tabs', className)}
			alignment={tabNavigationAlignment}
			defaultValue={defaultIndex ? headers[defaultIndex].id : undefined}
			switchMode="unmount" // useful for carousel that need to be in viewport to render correctly
			variant="scrollable"
			items={Children.map(children, (child, index) => ({
				content: child,
				label: headers[index].label,
				name: headers[index].id,
				code: headers[index].code,
				aboveTheFold: aboveTheFold,
			}))}
		/>
	)
}

export default TabsRenderer
