import Tabs from '@bluheadless/ui/src/molecules/tabs'
import { styled } from '@mui/system'

export const TabsStyled = styled(Tabs)`
	.MuiTabs-root {
		min-height: 20px;

		.MuiTab-root {
			text-transform: uppercase;
			padding: 0;
			font-family: var(--font-base-semibold);
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			margin: 0 ${({ theme }) => theme.spacing(2)};
			min-width: 0;
			min-height: 0;

			&:hover {
				background: transparent;
			}
		}
	}
`
